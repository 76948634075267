// extracted by mini-css-extract-plugin
export var communityFreeflow = "solutions-module--communityFreeflow--57EEq";
export var emphasized = "solutions-module--emphasized--x32kv";
export var staffBenefits = "solutions-module--staffBenefits--3bKaE";
export var staffBenefitsHoverable = "solutions-module--staffBenefitsHoverable--lwiO7";
export var staffContainer = "solutions-module--staffContainer--0iBvH";
export var staffContent = "solutions-module--staffContent--4PPNQ";
export var staffMinimized = "solutions-module--staffMinimized--7AYFd";
export var staffPlayIcon = "solutions-module--staffPlayIcon--ryJVY";
export var videoContainer = "solutions-module--videoContainer--9uT-k";
export var videoContainerVertical = "solutions-module--videoContainer--vertical--BvXEU";
export var videoHeader = "solutions-module--videoHeader--UgADs";
export var videoImage = "solutions-module--videoImage--OSA4a";
export var videoImageHoverable = "solutions-module--videoImageHoverable--jpXTZ";